import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import Layout from '../../components/Layout';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { getNewsDetails } from '../../reducers/News/actions'
import { getArticleDetails } from '../../reducers/Articles/actions'
import { connect } from 'react-redux'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";
import './styles.scss';

const NewsSingle = ({ details, pageContext, getArticleDetails, getNewsDetails }) => {

  let url = "";
  let serviceId = pageContext && pageContext.item && pageContext.item.id;

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  useEffect(() => {
   if(pageContext.type === "articles") {
     getArticleDetails({id: serviceId})
    } else {
      getNewsDetails({id: serviceId})
    }
  }, [])
  
  let imageResponse = getImage(pageContext.item?.image.localFile.childImageSharp)
  let image = imageResponse && imageResponse.images.fallback.src 

  return (
    <Layout
      url={url}
      title={pageContext && pageContext.item?.title
        ? pageContext.item.title
        : details && details.title
          ? details.title
          : null} 
      image={pageContext && imageResponse && image}
      description={pageContext && pageContext.item?.description}
      >
      <div className='news-single-wrapper'>
        <div className='news-single-container'>
          <Row className='news-single-row'>
            <Col lg={5}>
              <h5 className="text-uppercase">
                {pageContext.type === "articles"
                  ? "Статии"
                  : "Новини"}
              </h5>
              <h1 className='mb-3 font-weight-bold'>
                {
                  pageContext && pageContext.item?.title
                    ? pageContext.item.title
                    : details && details.title
                      ? details.title
                      : null
                }
              </h1>
              <div className='card-description mb-3'>
                {pageContext && pageContext.item?.created_on}
              </div>
              <div style={{ marginBottom: '100px'}}>
                <FacebookShareButton
                  imageURL={details && details.image && details.image.url && `https://building-companion.bg${details.image.url}`}
                  url={serviceId && 
                    pageContext.type === "articles" 
                    ? `https://building-companion.bg/articles-single/${serviceId}`
                    : `https://building-companion.bg/news-single/${serviceId}`
                  }
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <LinkedinShareButton
                  className="margin-left5"
                  url={serviceId && 
                    pageContext.type === "articles" 
                    ? `https://building-companion.bg/articles-single/${serviceId}`
                    : `https://building-companion.bg/news-single/${serviceId}`
                  }
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </div>
              <div className='all-news-link-container'>
                <a className='blue-link' href={pageContext.type === "articles" ? '/articles' : '/news'}>
                  {pageContext.type === "articles"
                    ? "Всички статии"
                    : "Всички новини"}
                </a>
              </div>
            </Col>
            <Col lg={7}>
              <div>
                <div className="news-single-img-container mt-xs-4 mt-md-4 mt-lg-0">
                  <GatsbyImage
                    image={getImage(pageContext.item?.image.localFile.childImageSharp)}
                    alt=""
                    style={{ height: 'inherit' }}
                    objectFit="cover"
                    layout="fixed"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <p
                dangerouslySetInnerHTML={pageContext && pageContext.item && { __html: pageContext && pageContext.item?.description }}
                className='card-description lead news-single-paragraph'
              />
            </Col>
          </Row>
          {
            pageContext &&
            pageContext.item &&
            pageContext.item.sections &&
            pageContext.item.sections.map((section, i) => {
              return (
                <Row
                  key={i}
                  className={section.image ? 'py-5' : ''}>
                  <Col>
                    <h4 className='description-title'>
                      {i + 1}. {section.title}
                    </h4>
                      {
                        details && details.sections &&
                        <p
                          className="pb-4"
                          dangerouslySetInnerHTML={{ __html: details.sections[i].description }}
                        />
                      }
                    {
                      section.image && 
                      <div>
                        <div className="news-single-description-image-container d-grid">
                            <GatsbyImage
                              image={section.image && getImage(section.image.localFile.childImageSharp)}
                              style={{ height: 'inherit' }}
                              alt=""
                              objectFit="cover"
                              layout="fixed"
                            />
                        </div>
                      </div>
                    }
                  </Col>
                </Row>
              )
            })
          }
        </div>
      </div>
    </Layout >
  );
}

const mapStateToProps = (state) => {
  return {
    details: state.articles.details
  };
};

const mapDispatchToProps = {
  getArticleDetails,
  getNewsDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsSingle);
